<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <!-- kriterij iskanja -->
            <v-layout row wrap align-left justify-space-around fill-height>
                <v-flex xs12 sm12 md12 lg12 xl12>
                    <v-card>
                            <v-card-title>
                                    <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Kriterij iskanja</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="toggleCardContent('card1')">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">

                                <!-- tip uporabnika -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row wrap justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Tip uporabnika</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_user_types"
                                            :items="userTypes"
                                            label="Izberi tip uporabnika"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- tipi omejitev -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Tip omejitve</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_restriction_types"
                                            :items="restrictionTypes"
                                            label="Izberi tip omejitve"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- telefonska številka
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Telefonska številka</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="phone_number"
                                            label="Vpiši telefonsko številko"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearPhoneNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>-->

                                <!-- registrska številka -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Registrska številka</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="registration_number"
                                            label="Vpiši registrsko številko"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearRegistrationNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Rezervacija</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="reservation"
                                            label="Vpiši rezervacijo"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearReservation"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Številka parcele</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="parcel_number"
                                            label="Vpiši številko parcele"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearParcelNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Številka sobe</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="room_number"
                                            label="Vpiši številko sobe"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearRoomNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Rezervacije</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox @change="displayResults()" class="ti" v-model="reservations_only" :label="`Prikaži samo rezervacije: ${checkboxReservationsOnlyLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Pretečena veljavnost</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox @change="displayResults()" class="ti" v-model="expired" :label="`Prikaži samo pretečene uporabnike: ${checkboxExpiredUsersOnlyLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Blokirani uporabniki</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox @change="displayResults()" class="ti" v-model="registration_numbers_disabled" :label="`Prikaži samo blokirane reg. številke: ${checkboxBlockedUsersOnlyLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                            </v-card-text>
                            <v-divider v-if="card1">
                            </v-divider>
                            <span v-if="card1">
                                <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn   @click="search()" color="secondary" dark  block outlined style=""><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov&nbsp;</v-btn>
                                </v-card-text>
                                <v-card-text v-else>
                                    <v-btn  @click="search()" color="secondary" dark text><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov&nbsp;</v-btn>
                                </v-card-text>
                            </span>
                    </v-card>
                </v-flex>
            </v-layout>
            <br>
            <users-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></users-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableSettings.id"
            :columns="dataTableSettings.headers"
            :dataTableColumnsMenuId="dataTableSettings.eventBus.toggleColumnsId"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate } from '@/helpers/utilities'
const UsersDataTable = () => import('@/components/UsersDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')
import { isMobileDevice } from '@/helpers/utilities.js'

export default {
    components: {
        UsersDataTable,
        DataTableToggleColumns
    },

    data: () => ({
        card1: true,
        card2: true,
        form: true,
        phone_number: null,
        registration_number: null,
        parcel_number: null,
        room_number: null,
        selected_user_types: [],
        selected_restriction_types: [],
        expired: false,
        registration_numbers_disabled: null,
        reservation: null,
        reservations_only: null,

        userTypes: [],
        restrictionTypes: [],

        dataTableSettings: {
            id: 'dtEvents',
            title: 'Seznam uporabnikov',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true},
                { text: "Naziv / Imetnik", value: "name", class: "text-xs-left", visibility: true},
                //{ text: "Imetnik", value: "reservation", class: "text-xs-left", visibility: true},
                { text: "Št. parcele", value: "parcel_number", class: "text-xs-left", visibility: true },
                { text: "Št. sobe", value: "room_number", class: "text-xs-left", visibility: true },
                { text: "Tip uporabnika", value: "user_type", class: "text-xs-left", visibility: true },
                { text: "Tip omejitve", value: "restriction", class: "text-xs-left", visibility: true },
                //{ text: "Telefonska št. 1", value: "phone_number_1", class: "text-xs-left", visibility: true },
                //{ text: "Telefonska št. 2", value: "phone_number_2", class: "text-xs-left", visibility: true },
                //{ text: "Registrska št. 1", value: "registration_number_1", class: "text-xs-left", visibility: true },
                //{ text: "Registrska št. 2", value: "registration_number_2", class: "text-xs-left", visibility: true },
                { text: "Registrske številke", value: "registration_numbers", class: "text-xs-left", visibility: true, sortable:false },
                { text: "Veljavnost od", value: "restriction_start_date", class: "text-xs-left", visibility: true, sortable:true },
                { text: "Veljavnost do", value: "restriction_end_date", class: "text-xs-left", visibility: true, sortable:true },
                //{ text: "Posodobitev", value: "updated_at", class: "text-xs-left", visibility: true, sortable:true },
                { text: "Upravljanje", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true},
            ],
            updateInterval: true,
            rowsPerPage: 10,
            endpoint: 'adria/rfid-users',
            editItem: {
                route: 'edit_user',
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: 'DELETE_RFID_USER',
                replaceStrWithColumnValue: 'name',
                confirmationText : "Sta prepričani da želite odstraniti uporabnika {str_to_replace} ?",
                dialogConfirmSuccessText: 'Uporabnik {str_to_replace} je bil uspešno odstranjen.',
                dialogConfirmFailText: 'Pri odstranjevanju uporabnika {str_to_replace} je prišlo do napake. Poskusite ponovno.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: true,
                    addNewRoute: 'add_user',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_rfid_users',
                totalItems : {
                    event: ''
                },
                search: 'dt-rfid-users-search',
                toggleColumnsId: 'toggle-rfid-users-list-columns'
            }


        }
    }),

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        queryParameters() {
            return {
                registrationNumber: this.registration_number,
                phoneNumber: this.phone_number,
                selectedUserTypes: this.selected_user_types.map(item => item.id),
                selectedRestrictionTypes: this.selected_restriction_types.map(item => item.id),
                parcelNumber: this.parcel_number,
                roomNumber: this.room_number,
                reservation: this.reservation,
                reservations_only: this.reservations_only,
                expired: this.expired,
                blockedUsers: this.registration_numbers_disabled
            }
        },

        checkboxExpiredUsersOnlyLabel() {
            if(this.expired) {
                return 'DA'
            }

            return 'NE'
        },

        checkboxReservationsOnlyLabel() {
            if(this.reservations_only) {
                return 'DA'
            }

            return 'NE'
        },

        checkboxBlockedUsersOnlyLabel() {
            if(this.registration_numbers_disabled) {
                return 'DA'
            }

            return 'NE'
        }
    },

    watch: {

    },

    methods: {
        toggleCardContent(card) {
            if(card === 'card1') {
                this.card1 = !this.card1
            }
        },

        search() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 250)
        },

        clearPhoneNumber() {
            this.phone_number = null
        },

        clearRoomNumber() {
            this.room_number = null
        },

        clearRegistrationNumber() {
            this.registration_number = null
        },

        clearParcelNumber() {
            this.parcel_number = null
        },

        clearReservation() {
            this.reservation = null
        },

        displayResults() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 280)
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 

        if(isMobileDevice(this)) {
            this.card1 = false
        }
    },

    mounted() {
        var vm = this
        vm.$store.commit('SET_PROGRESS', true)
        vm.$store.dispatch('PARAMETERS_DATA')
        .then(response => {
            //window.console.log(response.data)
            response.data.userTypes.forEach(userType => {
                vm.userTypes.push(userType)
            })

            response.data.restrictionTypes.forEach(restrictionType => {
                vm.restrictionTypes.push(restrictionType)
            })
        })
        .catch(error => {
            window.console.error("### ERROR Users.vue@mounted ###");
            window.console.error(error)
        })
        .finally(() => {
            vm.$store.commit('SET_PROGRESS', false)
        })
    },

    destroyed() {
        EventBus.$off(this.dataTableSettings.id)

        this.$store.commit('SET_SEARCH', '');
        this.$store.commit('SET_SEARCH_FIELD_STATE', false);
        this.$store.commit('SET_MOBILE_SEARCH', false)  
    }
}

</script>

<style scoped>

</style>